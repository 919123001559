import React, { useEffect, useState } from "react";
import MainTitle from "../MainTitle/MainTitle";
import Button from "../Button/Button";
import { toast } from "react-hot-toast";
import ABT from "../img/ABT.png";
import useEncryption from "../EncryptData/EncryptData";
import instance from "../BaseUrl/BaseUrl";
import Web3 from "web3";
import { ethers } from "ethers";
import { withdrawABI, wizzABI } from "../../ABI";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import { fetchData } from "../Feature/User";

const AbtStaking = () => {
  const walletID = localStorage.getItem("metamask");
  const data = [
    {
      id: 1,
      time: "12 mths",
      stakeRewards: "0.60% daily",
      grids: "grid justify-self-end",
    },
    {
      id: 2,
      time: "6 mths",
      stakeRewards: "0.40% daily",
      grids: "grid justify-self-start lg:mt-0 mt-4",
    },
  ];

  // historyData //
  const historyData = useSelector((state) => state?.user?.historyData?.ABTStakingData)
  console.log("🚀🚀🚀 ~ historyData:--->>", historyData)
  const [unstakeBalance, setUnstakeBalance] = useState(0)
  const [selectedOption, setSelectedOption] = useState("");
  // console.log(historyData, "<=======historyData");
  // historyData //
  ////////////////////////////
  // reffered number//
  // const uniqueValues = new Set(historyData?.staking.map((e) => e.walletAddress));
  // if (uniqueValues.size < historyData?.staking.length) {
  //   console.log("😎 duplicates found");
  // } else {
  //   console.log("🥱 duplicates NOT found");
  // }
  // reffered number//
  //////////////////////////
  // StakedABT //
  let StakedABT =
    historyData?.ABTStaking >= 0 ||
      historyData?.ABTStaking === undefined ||
      historyData?.ABTStaking === null
      ? console.log("wrong")
      : historyData?.ABTStaking.reduce(function (tot, arr) {
        return tot + arr.amount;
      }, 0);


  const [address, setaddress] = useState("");
  const [error, setError] = useState();
  const { encryptData, decryptData } = useEncryption();
  const [loading, setLoading] = useState(false);
  const [metaLoader, setMetaLoader] = useState(false);
  const [loading1, setLoading1] = useState(false);
  console.log("🚀 ~ Staking ~ loading1:", loading1);

  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const getdata = decryptData(localStorage.getItem("details"));

  const [amount, setAmount] = useState(1000);
  // const [amount, setAmount] = useState(5000);
  const dispatch = useDispatch();

  const [walletAddress, setwalletAddress] = useState(
    localStorage.getItem("metamask")
  );
  // console.log("🚀 ~ Staking ~ walletAddress:", walletAddress);
  // const [referedBy, setReferedBy] = useState("");
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [userWithdrawStatus, setUserWithdrawStatus] = useState();
  console.log("🚀 ~ userWithdrawStatus:", userWithdrawStatus);
  const [currentAccount, setCurrentAccount] = useState("");
  const checkIfAccountChanged = async () => {
    try {
      const { ethereum } = window;
      ethereum.on("accountsChanged", (accounts) => {
        // console.log("Account changed to:", accounts[0]);
        setCurrentAccount(accounts[0]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const status = localStorage.getItem("blockWithdraw");
    setUserWithdrawStatus(status);
  }, []);
  useEffect(() => {
    checkIfAccountChanged();
  }, []);

  const networks = {
    bsc: {
      chainId: `0x${Number(56).toString(16)}`,
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.binance.org",
        "https://bsc-dataseed2.binance.org",
        "https://bsc-dataseed3.binance.org",
        "https://bsc-dataseed4.binance.org",
        "https://bsc-dataseed1.defibit.io",
        "https://bsc-dataseed2.defibit.io",
        "https://bsc-dataseed3.defibit.io",
        "https://bsc-dataseed4.defibit.io",
        "https://bsc-dataseed1.ninicoin.io",
        "https://bsc-dataseed2.ninicoin.io",
        "https://bsc-dataseed3.ninicoin.io",
        "https://bsc-dataseed4.ninicoin.io",
        "wss://bsc-ws-node.nariox.org",
        "https://bsc.publicnode.com",
      ],
      blockExplorerUrls: ["https://bscscan.com"],
    },
  };

  const changeNetwork = async ({ networkName, setError }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");

      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleNetworkSwitch = async (networkName) => {
    setError();

    await changeNetwork({ networkName, setError });
  };

  const openmetamask = async () => {
    if (!window.ethereum) {
      toast.error("No crypto wallet found");
      window.open("https://metamask.io/");
    } else {
      const wallet = await window?.ethereum?.enable();
      setaddress(wallet?.toString());
      handleNetworkSwitch("bsc");
    }
  };

  const changeToBNBChain = async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x38" }], //MAIN BSC 0x38      // bsc testnet= 0x61
    });
  };

  let web3 = null;
  let instanced = null;
  let chainId = null;

  const transferFrom_address_to_address = async (amount) => {
    try {
      web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();
      chainId = await web3.eth.getChainId();
      let amt = amount;
      console.log("🚀 ~ consttransferFrom_address_to_address= ~ amt:", amt)
      const tokenAddress = "0x9E021D904dc20d337739a14D487d79C99aD07598";
      const paymentAddress = "0x32B9143747360Bcd1D935956df59A3563F792620";

      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (account) => {
          if (chainId !== 56) {
            await changeToBNBChain();
          }
          instanced = new web3.eth.Contract(wizzABI, tokenAddress);
          const parsedAmount = ethers.utils.parseUnits(amt.toString(), 18);
          instanced.methods
            .transfer(paymentAddress, parsedAmount)
            .send({
              from: account[0],
              gas: 100000, // Set a default value for gas (you can adjust this as needed)
              gasPrice: "3000000000", // Set a default gas price (in wei) or you can use 'fast' from gas station API
              gasLimit: {
                minimum: 1000, // Minimum gas limit for simple transfer transactions
                maximum: 2000000, // Maximum gas limit (you can adjust this as needed)
              },
            })
            .on("transactionHash", function (hash) {
              // console.log("🚀 ~ hash:", hash);
              setLoading(true);
            })
            .on("receipt", function (receipt) {
              console.log("🚀 ~ receipt:", receipt);
            })
            .on("confirmation", async function (confirmationNumber, receipt) {
              if (receipt?.status && confirmationNumber === 0) {
                setLoading(false);
                toast.success(`Successfully Staked ${amount} ABT`);
                const encrypt1 = encryptData(
                  JSON.stringify({
                    userId: localStorage.getItem("userId"),
                    amount: amount,
                    duration: 12,
                    // referedBy: localStorage.getItem("userId"),
                    walletAddress: walletID,
                  })
                );

                const resultstaking = await instance.post("/abtStaking", {
                  data: encrypt1,
                });

                const resultsstaking = decryptData(resultstaking.data.data);
                console.log("🚀 ~ resultsstaking:", resultsstaking)

                if (resultsstaking.status) {
                  const encrypt = encryptData(
                    JSON.stringify({
                      userId: localStorage.getItem("userId"),
                      amount: amount,
                      // referedBy: localStorage.getItem("userId"),
                      walletAddress: walletID,
                    })
                  );
                  const abtRefralDistribution = await instance.post("/abtRefralDistribution", {
                    data: encrypt,
                  });
                  const resultsabtRefralDistribution = decryptData(abtRefralDistribution.data.data);
                  console.log("🚀 ~ abtRefralDistribution:", resultsabtRefralDistribution)
                  window.location.reload(true)
                } else {
                  toast.error(resultsstaking.message);
                }
              } else {
                console.log("Something went wrong ⚠");
              }
            })
            .on("error", function (error, receipt) {
              // console.log("🚀 ~ receipt:", receipt);
              toast.error("something went wrong, not confirmed staking");
              // console.log("🚀 ~ error:", error);
            });
        });
    } catch (error) {
      setLoading(false);
      // toast.error("🚀 ~ consttransferFrom_address_to_address= ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const walletAd = localStorage.getItem("metamask");
  // console.log("🚀 ~ Staking ~ walletAd:", walletAd);
  const transferToken = async () => {
    // if (amount) {
    if (amount >= 1000) {

      // console.log("address=======>", address);
      if (
        walletAd === undefined ||
        walletAd === "" ||
        walletAd === null
      ) {
        toast.error("Please connect your wallet");
      } else if (!window.ethereum) {
        toast.error("No crypto wallet found");
        window.open("https://metamask.io/");
      } else {
        try {
          amount === "" && toast.error("Enter Amount");
          await window?.ethereum?.enable();
          // const wallet = await window?.ethereum?.enable();
          // localStorage.setItem("metamask1", wallet[0].toString());
          // setwalletAddress(wallet[0]?.toString());
          await transferFrom_address_to_address(amount.toString());
        } catch (err) {
          // toast.error(err.message);
          toast.error("Please try again later");
          // window.open("https://metamask.io/");
        }
      }
    } else {
      alert("Required Minimum Amount 1000");
    }
    // toast.error(
    //   "Due to an urgent contract upgrade, withdrawal has paused. It will resume shortly.Appreciate your patience.",
    //   { duration: 3000 }
    // );
  };
  // const transferWizzTokens = async () => {
  //   try {
  //     const wallet = new ethers.Wallet(wizzPrivateKey, provider);
  //     const tokenAddress = "0x2aD69E58071b95C047bF78dc804DB3662275b64A"; //token address wec.
  //     const tokenContract = new ethers.Contract(tokenAddress, wizzABI, wallet);

  //     const parsedAmount = ethers.utils.parseUnits(
  //       withdrawAmount.toString(),
  //       18
  //     );
  //     console.log("🚀 ~ transferWizzTokens ~ parsedAmount:", parsedAmount);

  //     setLoading2(true);
  //     const tx = await tokenContract.transfer(walletAddress, parsedAmount, {
  //       // gasPrice: 10000000000,
  //       // gasLimit: 8500000,
  //     });
  //     console.log(`Transaction hash: ${tx.hash}`);

  //     const receipt = await tx.wait();
  //     console.log(`Transaction confirmed in block ${receipt.blockNumber}`);

  //     if (receipt.blockNumber) {
  //       toast.success(`Withdrawn of ${withdrawAmount} tokens is Successful`);
  //       window.location.reload(true);
  //     }
  //   } catch (error) {
  //     toast.error("Please try again later");
  //     console.log("error-------------", error);
  //   } finally {
  //     setLoading2(false);
  //     // closeModal();
  //   }
  // };
  // const WithdrawWEC = async (e) => {
  //   // e.preventDefault();

  //   try {
  //     const WithdrawWECData = await instance.post("/withdrawStakingProfit", {
  //       amount: withdrawAmount,
  //       toAddress: walletAd,
  //       role: "PROFIT",
  //     });
  //     const decryptDataData = decryptData(WithdrawWECData.data.data);
  //     if (decryptDataData.status === true) {
  //       transferWizzTokens();
  //       // toast.success(decryptDataData.message);
  //     } else {
  //       toast.error(decryptDataData.message);
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ submitHandler ~ err:", error);
  //     toast.error("Please try again later");
  //   }
  //   // finally {
  //   //   setLoading2(false);
  //   // }
  // };
  // const WithdrawRefaralProfit = async () => {
  //   try {
  //     setLoading2(true);
  //     const WithdrawWECData = await instance.post("/withdrawRefaralProfit", {
  //       amount: withdrawAmount,
  //       toAddress: walletAd,
  //       role: "REFARAL_PROFIT",
  //     });
  //     const decryptDataData = decryptData(WithdrawWECData.data.data);
  //     if (decryptDataData.status === true) {
  //       toast.success(decryptDataData.message) && window.location.reload(true);
  //     } else {
  //       toast.error(decryptDataData.message);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading2(false);
  //   }
  // };
  // const historyData = async () => {
  //   try {
  //     const result = await instance.get(`/stakingDetails/${walletAddress}`);
  //     const results = decryptData(result.data.data);
  //     if (results.status) {
  //       toast.success(results.message);
  //       // setbusdprice(results.data.price);
  //     } else {
  //       toast.error(results.message);
  //     }
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   historyData();
  // }, []);

  //withdraw
  const withdrawWizzTokens = async () => {
    // Check if MetaMask is installed and has a provider

    try {
      const contractaddress = "0x05879bDb48F58594e88dfF248355C1f7F54E5dF0";

      const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
      console.log("🚀 ~ transferWizzTokens ~ walletProvider:", walletProvider);
      const sendContractTx = new ethers.Contract(
        contractaddress,
        withdrawABI,
        walletProvider.getSigner()
      );

      // Check if value is defined and not null
      setLoading1(false);
      if (withdrawAmount) {
        const balanceInEther = ethers.utils.parseUnits(
          withdrawAmount.toString(),
          18
        );
        const gasLimit = 300000;
        const withdrawData = await sendContractTx.withdraw(balanceInEther, {
          gasLimit,
        });
        if (withdrawData) {
          // if (selectedOption === "WEC Rewards") {
          //   WithdrawWEC();
          // } else {
          //   WithdrawRefaralProfit();
          // }
          toast.success("withdraw successfully");
        }
      } else {
        console.log("Value is not defined or null");
      }
    } catch (error) {
      // console.log("🚀 ~ transferWizzTokens ~ error===================:", error);
      toast.error("Please try again later");
    } finally {
      setLoading(false);
      setLoading2(false);
      // closeModal();
    }
  };

  const WithdrawWEC = async () => {
    if (withdrawAmount >= 1000) {
      try {
        setLoading2(true);
        const WithdrawWECData = await instance.post("/AbtWithdrawStakingProfit", {
          amount: withdrawAmount,
          toAddress: walletAd,
          role: "PROFIT",
        });

        const decryptDataData = decryptData(WithdrawWECData?.data?.data);
        console.log("🚀 ~ WithdrawWEC ~ decryptDataData:", decryptDataData);
        if (decryptDataData.status === true) {
          // withdrawWizzTokens();
          toast.success(decryptDataData.message) && window.location.reload(true);
          // toast.success(decryptDataData.message)
          setLoading2(false);
        } else {
          setLoading2(false);
          toast.error(decryptDataData.message);
        }
      } catch (error) {
        setLoading2(false)
        console.log("🚀 ~ WithdrawWEC ~ error:", error)
        toast.error("Try agin later");
      }
    } else {
      toast.error("The amount must be greater than or equal to 1000");
    }
    // finally {
    //   setLoading2(false);
    // }
  };
  const WithdrawRefaralProfit = async () => {
    if (withdrawAmount >= 1000) {
      try {
        setLoading2(true);
        const WithdrawWECData = await instance.post("/AbtWithdrawRefaralProfit", {
          amount: withdrawAmount,
          toAddress: walletAd,
          role: "REFARAL_PROFIT",
        });

        const decryptDataData = decryptData(WithdrawWECData?.data?.data);
        console.log(
          "🚀 ~ WithdrawRefaralProfit ~ decryptDataData:",
          decryptDataData
        );
        if (decryptDataData.status === true) {
          // withdrawWizzTokens();
          toast.success(decryptDataData.message) && window.location.reload(true);
          // toast.success(decryptDataData.message)
          setLoading2(false);
        } else {
          setLoading2(false);
          toast.error(decryptDataData.message);
        }
      } catch (error) {
        console.log("🚀 ~ WithdrawRefaralProfit ~ error:", error)
        toast.error("Try agin later");
        setLoading2(false)
      }
      //  finally {
      //   setLoading2(false);
      // }
    } else {
      toast.error("The amount must be greater than 1000");
    }
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // if (selectedValue === "WEC Rewards") {
    //   WithdrawWEC();
    // } else if (selectedValue === "WEC Earnings") {
    //   WithdrawRefaralProfit();
    // }
  };

  const withdrawUnstakedAmount = async () => {
    const withdrawUnstakedAmountData = await instance.post("/withdrawUnstakedAmount", {
      amount: 1,
      toAddress: localStorage.getItem("metamask"),
    });
    const decryptDataData = decryptData(withdrawUnstakedAmountData?.data?.data);
    console.log("🚀 ~ withdrawUnstakedAmount ~ decryptDataData:", decryptDataData)
  }


  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (loading) {
  //       event.preventDefault();
  //       event.returnValue = 'Your Transaction is in process'; 
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [loading]);
  return (
    <>
      {loading2 && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <div className="flex justify-center items-center flex-col">

                <p className="text-center text-xl">
                  Please wait...Your transaction is being processed...
                </p>
                <p className="text-center mt-5">
                  <Circles
                    height="50"
                    width="50"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading3 && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <p className="text-center">
                If you have staked for 1st time, Please wait for 24 hours to
                withdraw.
              </p>
            </div>
          </div>
        </div>
      )}
      {withdrawPopup && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          {metaLoader ? (
            <div className="text-white text-4xl">Loading...</div>
          ) : (
            <div className="bg-[#030239] p-10 rounded-3xl grid place-content-center">
              <div className="mb-5">
                <label className="text-white text-lg">Please Select</label>
                <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                  <select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    className="w-full bg-transparent p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg"
                  >
                    <option value="">Select an option</option>
                    <option value="ABT Rewards">ABT Rewards</option>
                    <option value="ABT Earnings">ABT Earnings</option>
                  </select>
                </div>
              </div>
              <div className="bot-left1 rounded-xl flex items-center text-[#A9A9A9] mt-1">
                <input
                  type="number"
                  value={withdrawAmount}
                  placeholder="Enter Amount"
                  min="1000"
                  className="py-5 px-5 md:px-10 nodetype-bg rounded-xl  text-white "
                  onChange={(event) => {
                    const amount = event.target.value;
                    if (selectedOption !== "") {
                      if (selectedOption === "ABT Rewards") {
                        if (amount >= historyData?.ABTStakingProfit) {
                          toast.error("not enough rewards");
                        } else {
                          setWithdrawAmount(amount);
                        }
                      } else if (selectedOption === "ABT Earnings") {
                        if (amount >= historyData?.ABTStakingRefaralProfit) {
                          toast.error("not enough earnings");
                        } else {
                          setWithdrawAmount(amount);
                        }
                      }
                    } else {
                      toast.error("Please select option");
                    }
                  }}
                />
              </div>
              {withdrawAmount < 1000 && <p className="text-red-500 text-sm w-52 mt-1 mx-2">The amount must be greater than or equal to 1000</p>}

              <div className="md:flex justify-center gap-10">
                <div
                  className="w-[50%] my-5 mx-auto"
                  // onClick={() => {
                  //   WithdrawWEC();
                  //   setWithdrawPopup(false);
                  // }}
                  // onClick={
                  //   () => {
                  //     transferWizzTokens();
                  //     setLoading1(true);
                  //   }
                  onClick={() => {
                    if (withdrawAmount >= 1000) {
                      // if (withdrawAmount) {
                      if (selectedOption === "ABT Rewards") {
                        WithdrawWEC();
                      } else if (selectedOption === "ABT Earnings") {
                        WithdrawRefaralProfit();
                      }

                      setWithdrawPopup(false);
                    }
                    else {
                      toast.error("Please enter more than 1000");
                    }
                  }
                  }
                // }
                >
                  <Button btn={"Send"} />
                </div>
                <div
                  className="w-[50%] my-5 mx-auto"
                  onClick={() => {
                    setWithdrawPopup(false);
                  }}
                >
                  <Button btn={"Cancel"} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {loading && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <p>
                Your transaction is under process. This will take upto 15
                seconds. Please wait...
              </p>
            </div>
          </div>
        </div>
      )}
      {(!loading || !loading2) && (
        <>
          <MainTitle title={"ABT STAKING"} />
          <div className="container mx-auto text-center mt-3 text-white">
            <h2 className="font-bold">
              INTRODUCING OUR CUTTING-EDGE CRYPTOCURRENCY STAKING PLATFORM;
            </h2>
            <p className="text-justify max-w-xs lg:max-w-3xl container mx-auto my-5">
              A revolutionary ecosystem empowering users to grow their ABT
              effortlessly. Through staking, users can securely lock their ABT
              and earn daily rewards. Our platform is a one-stop solution for
              individuals seeking to maximize their earnings and engage with the
              dynamic world of cryptocurrencies.
            </p>
            <h2 className="font-bold">
              JOIN US TODAY AND UNLOCK THE TRUE POTENTIAL OF YOUR ABT.
            </h2>
          </div>
          <div className="text-white">
            <div className=" mt-10 flex justify-center ">
              <div
                className={`lg:w-[60%]  md:w-[70%] md:mx-auto mx-5 lg:mx-5 grid grid-cols-2 place-items-center place-content-center justify-self-end gap-6 p-[1px] bot-left1 rounded-lg`}
              >
                <div className="p-4">
                  <p className="text-sm mb-1 text-white/60">Locking period</p>
                  <p className="font-medium text-lg">12 Mths</p>
                </div>
                <div className="p-4">
                  <p className="text-sm mb-1 text-white/60">Multiplier</p>
                  <p className="font-medium text-lg">0.50% daily</p>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:grid grid-cols-2 justify-items-stretch ">
            <div className="lg:w-[60%] md:w-[70%] md:mx-auto mx-5 lg:mx-5 grid justify-self-end">
              <div className="nodetype-bg  rounded-2xl items-stretch md:p-16 p-10 flex flex-col gap-5 justify-center mt-10">
                <div className="text-white">
                  <div className="flex justify-between">
                    <label className="text-white text-lg">Amount</label>
                    <div className="lg:mt-2 text-sm text-gray-400">
                      Minimum 1000
                    </div>
                  </div>
                  <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                    <input
                      className={`w-full bg-transparent  p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg`}
                      type="text"
                      name="amount"
                      placeholder="Enter Amount Minimum 500"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  {amount < 1000 && (
                    <p className="text-red-500">required minimum 1000</p>
                  )}
                </div>
                <div>
                  <label className="text-white text-lg">
                    Duration (Months)
                  </label>
                  <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                    <input
                      className={`w-full bg-transparent  p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg`}
                      type="text"
                      name="amount"
                      placeholder="Enter Amount Minimum 500"
                      value={12}
                      disabled
                    />
                    {/* <select
                      onChange={(e) => setDuration(e.target.value)}
                      className="w-full bg-transparent  p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg"
                    >
                      <option value="6">6</option>
                    </select> */}
                  </div>
                </div>
                <div className="flex justify-center gap-4 my-4">
                  <div className="flex justify-center">
                    <div
                      onClick={() => {
                        transferToken();
                        // setLoading2(true);
                        // toast.error("Staking is closed")
                        // transferFrom_address_to_address(amount.toString())
                      }}
                    >
                      <Button btn={"Stake ABT"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-[60%] md:w-[70%] md:mx-auto mx-5 lg:mx-5 grid justify-self-start ">
              <div className="md:mt-10 mt-4 text-white">
                <div>
                  <div className="nodetype-bg rounded-lg bg-black/10 w-full  md:py-10 py-10 px-5">
                    <div className="p-4">
                      <div className="flex justify-center text-center border-b-2 border-[#393d60] mb-4">
                        <span className="md:text-xl mb-3">ABT Staking Data</span>
                      </div>
                      <div className="flex gap-5 justify-between">
                        <div>
                          <div className="flex gap-2 items-center border-b-2 border-b-gray-700">
                            <p>Wallet Referred :-</p>
                            <div className="flex gap-2 items-center my-2 ">
                              <p className="mt-1">{`${historyData >= 0 ||
                                historyData === undefined ||
                                historyData === null ||
                                historyData.referedWallets === undefined
                                ? "(0)"
                                : historyData.referedWallets
                                }`}</p>
                              <img
                                src={ABT}
                                alt="weclogo"
                                className=""
                              />
                            </div>
                            {/* <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`(${
                              historyData?.staking >= 0 ||
                              historyData?.staking === undefined ||
                              historyData?.staking === null
                                ? "(0)"
                                : historyData?.staking.length
                            })`}</p>
                            <img
                              src={weclogo}
                              alt="weclogo"
                              className=""
                            />
                          </div> */}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (historyData?.ABTStaking?.length <= 0) {
                              toast.error("Please Stake ABT First")
                            }
                            else {

                              if (
                                localStorage.getItem("blockWithdraw") ===
                                "Unblocked" ||
                                localStorage.getItem("blockWithdraw") === null ||
                                localStorage.getItem("blockWithdraw") ===
                                undefined
                              ) {
                                if (
                                  localStorage.getItem("metamask") === "" ||
                                  localStorage.getItem("metamask") ===
                                  undefined ||
                                  localStorage.getItem("metamask") === null
                                ) {
                                  toast.error("Please connect wallet");
                                }
                                // else if (historyData?.profit > 0) {
                                //   setWithdrawPopup(true);
                                // }
                                else {
                                  setWithdrawPopup(true);
                                }
                              } else {
                                toast.error("contact admin to restore account");
                              }
                              // // WithdrawWEC();
                              // toast.error(
                              //   "Due to an urgent contract upgrade, withdrawal has paused. It will resume shortly.Appreciate your patience..",
                              //   { duration: 3000 }
                              // );
                            }
                          }}
                        >
                          <Button btn={"Withdraw"} />
                        </div>
                      </div>

                      <div className="mt-10 grid md:grid-cols-2 gap-8">
                        <div>
                          <p>Staked ABT</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`${StakedABT ? StakedABT : "(0)"}`}</p>
                            {/* <p className="mt-1 text-gray-400 text-xs">(ABT)</p> */}
                            <img
                              src={ABT}
                              alt="ABT"
                              className=""
                            />
                            {/* <img
                              src={wizzlogo}
                              alt="wizzlogo"
                              className="w-8 h-4"
                            /> */}
                          </div>
                        </div>

                        <div>
                          <p>ABT Rewards</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`${historyData >= 0 ||
                              historyData === undefined ||
                              historyData === null ||
                              historyData?.ABTStakingProfit === undefined
                              ? "(0)"
                              : historyData?.ABTStakingProfit?.toFixed(2)
                              }`}</p>
                            {/* <p className="mt-1 text-gray-400 text-xs">(ABT)</p> */}
                            <img
                              src={ABT}
                              alt="ABT"
                              className=""
                            />
                          </div>
                        </div>
                        <div>
                          <p>ABT Earnings</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`${historyData >= 0 ||
                              historyData === undefined ||
                              historyData === null ||
                              historyData?.ABTStakingRefaralProfit === undefined
                              ? "(0)"
                              : historyData?.ABTStakingRefaralProfit?.toFixed(2)
                              }`}</p>
                            {/* <p className="mt-1 text-gray-400 text-xs">(ABT)</p> */}
                            <img
                              src={ABT}
                              alt="ABT"
                              className=""
                            />
                          </div>
                        </div>
                        <div>
                          <p>Available Unstake Balance</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">
                              {historyData?.ABTStakingUnstakableAmount ? historyData?.ABTStakingUnstakableAmount : "(0)"}
                            </p>
                            {/* <p className="mt-1 text-gray-400 text-xs">(ABT)</p> */}
                            <img
                              src={ABT}
                              alt="ABT"
                              className=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AbtStaking;