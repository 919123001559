import React, { useEffect, useState } from "react";
import MainTitle from "../MainTitle/MainTitle";
import Button from "../Button/Button";
import { toast } from "react-hot-toast";
// import wizzlogo from "../img/wizz-logo.png";
import weclogo from "../img/wec.png";
import useEncryption from "../EncryptData/EncryptData";
import instance from "../BaseUrl/BaseUrl";
import Web3 from "web3";
import { ethers } from "ethers";
import { withdrawABI, wizzABI, wizzPrivateKey } from "../../ABI";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import { fetchData } from "../Feature/User";

const Staking = () => {
  const walletID = localStorage.getItem("metamask");
  const [showModal, setShowModal] = useState(true);
  const data = [
    {
      id: 1,
      time: "12 mths",
      stakeRewards: "0.60% daily",
      grids: "grid justify-self-end",
    },
    {
      id: 2,
      time: "6 mths",
      stakeRewards: "0.40% daily",
      grids: "grid justify-self-start lg:mt-0 mt-4",
    },
  ];

  // historyData //
  // const { historyData } = useSelector((state) => state.user);
  const historyData = useSelector((state) => state?.user?.historyData?.WECStakingData)
  // console.log("🚀 ~ datas:+++++++++++++++++++++++++++++++++++++", datas)
  // console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ historyData:", historyData)

  // const [unstakeBalance, setUnstakeBalance] = useState(0)
  const [selectedOption, setSelectedOption] = useState("");
  // console.log(historyData, "<=======historyData");
  // historyData //
  ////////////////////////////
  // reffered number//
  // const uniqueValues = new Set(historyData?.staking.map((e) => e.walletAddress));
  // if (uniqueValues.size < historyData?.staking.length) {
  //   console.log("😎 duplicates found");
  // } else {
  //   console.log("🥱 duplicates NOT found");
  // }
  // reffered number//
  //////////////////////////
  // StakedWEC //
  let StakedWEC =
    historyData?.staking >= 0 ||
      historyData?.staking === undefined ||
      historyData?.staking === null
      ? console.log("wrong")
      : historyData?.staking.reduce(function (tot, arr) {
        return tot + arr.amount;
      }, 0);
  // StakedWEC //
  ////////////////////////////
  // WithdrawableWEC //
  let WithdrawableWEC =
    historyData?.staking >= 0 ||
      historyData?.staking === undefined ||
      historyData?.staking === null
      ? console.log("wrong")
      : historyData?.staking.reduce(function (tot, arr) {
        return tot + arr.profit;
      }, 0);
  // WithdrawableWEC //
  ////////////////////////////

  const [address, setaddress] = useState("");
  const [error, setError] = useState();
  const { encryptData, decryptData } = useEncryption();
  const [loading, setLoading] = useState(false);
  const [metaLoader, setMetaLoader] = useState(false);
  const [loading1, setLoading1] = useState(false);
  console.log("🚀 ~ Staking ~ loading1:", loading1);

  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const getdata = decryptData(localStorage.getItem("details"));

  const [amount, setAmount] = useState(20000);
  // const [amount, setAmount] = useState(5000);
  const [duration, setDuration] = useState(6);
  const dispatch = useDispatch();

  const [walletAddress, setwalletAddress] = useState(
    localStorage.getItem("metamask")
  );
  // console.log("🚀 ~ Staking ~ walletAddress:", walletAddress);
  // const [referedBy, setReferedBy] = useState("");
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [userWithdrawStatus, setUserWithdrawStatus] = useState();
  console.log("🚀 ~ userWithdrawStatus:", userWithdrawStatus);
  const [currentAccount, setCurrentAccount] = useState("");
  const checkIfAccountChanged = async () => {
    try {
      const { ethereum } = window;
      ethereum.on("accountsChanged", (accounts) => {
        // console.log("Account changed to:", accounts[0]);
        setCurrentAccount(accounts[0]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const status = localStorage.getItem("blockWithdraw");
    setUserWithdrawStatus(status);
  }, []);
  useEffect(() => {
    checkIfAccountChanged();
  }, []);

  const networks = {
    bsc: {
      chainId: `0x${Number(56).toString(16)}`,
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.binance.org",
        "https://bsc-dataseed2.binance.org",
        "https://bsc-dataseed3.binance.org",
        "https://bsc-dataseed4.binance.org",
        "https://bsc-dataseed1.defibit.io",
        "https://bsc-dataseed2.defibit.io",
        "https://bsc-dataseed3.defibit.io",
        "https://bsc-dataseed4.defibit.io",
        "https://bsc-dataseed1.ninicoin.io",
        "https://bsc-dataseed2.ninicoin.io",
        "https://bsc-dataseed3.ninicoin.io",
        "https://bsc-dataseed4.ninicoin.io",
        "wss://bsc-ws-node.nariox.org",
        "https://bsc.publicnode.com",
      ],
      blockExplorerUrls: ["https://bscscan.com"],
    },
  };

  const changeNetwork = async ({ networkName, setError }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");

      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleNetworkSwitch = async (networkName) => {
    setError();

    await changeNetwork({ networkName, setError });
  };

  const openmetamask = async () => {
    if (!window.ethereum) {
      toast.error("No crypto wallet found");
      window.open("https://metamask.io/");
    } else {
      const wallet = await window?.ethereum?.enable();
      setaddress(wallet?.toString());
      handleNetworkSwitch("bsc");
    }
  };

  const changeToBNBChain = async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x38" }], //MAIN BSC 0x38      // bsc testnet= 0x61
    });
  };

  let web3 = null;
  let instanced = null;
  let chainId = null;

  const transferFrom_address_to_address = async (amount) => {
    try {
      web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();
      chainId = await web3.eth.getChainId();
      let amt = amount;
      console.log("🚀 ~ consttransferFrom_address_to_address= ~ amt:", amt)
      // const tokenAddress = "0x2aD69E58071b95C047bF78dc804DB3662275b64A";
      const tokenAddress = "0xa65Dda13aACEEd7C906Dfe7C6Cec805Ae3d8ED71";

      const paymentAddress = "0x60a68f8490f249Feba66DE40ad09DE8118B16535";
      // const paymentAddress = "0x0507f0ff0383c7CbFE5D6175F12aE63281864242";
      // const paymentAddress = "0x23874afc3E1992215f08d16ea7490DD8bE56b518";

      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (account) => {
          if (chainId !== 56) {
            await changeToBNBChain();
          }
          instanced = new web3.eth.Contract(wizzABI, tokenAddress);
          const parsedAmount = ethers.utils.parseUnits(amt.toString(), 18);
          instanced.methods
            .transfer(paymentAddress, parsedAmount)
            .send({
              from: account[0],
              gas: 100000, // Set a default value for gas (you can adjust this as needed)
              gasPrice: "3000000000", // Set a default gas price (in wei) or you can use 'fast' from gas station API
              gasLimit: {
                minimum: 1000, // Minimum gas limit for simple transfer transactions
                maximum: 2000000, // Maximum gas limit (you can adjust this as needed)
              },
            })
            .on("transactionHash", function (hash) {
              // console.log("🚀 ~ hash:", hash);
              setLoading(true);
            })
            .on("receipt", function (receipt) {
              console.log("🚀 ~ receipt:", receipt);
            })
            .on("confirmation", async function (confirmationNumber, receipt) {
              if (receipt?.status && confirmationNumber === 0) {
                setLoading(false);
                toast.success(`Successfully staked ${amount} WEC`);
                const encrypt1 = encryptData(
                  JSON.stringify({
                    userId: localStorage.getItem("userId"),
                    amount: amount,
                    duration: duration,
                    // referedBy: localStorage.getItem("userId"),
                    walletAddress: walletID,
                  })
                );

                const resultstaking = await instance.post("/staking", {
                  data: encrypt1,
                });

                const resultsstaking = decryptData(resultstaking.data.data);

                if (resultsstaking.status) {
                  const encrypt = encryptData(
                    JSON.stringify({
                      userId: localStorage.getItem("userId"),
                      amount: amount,
                      // referedBy: localStorage.getItem("userId"),
                      walletAddress: walletID,
                    })
                  );
                  await instance.post("/refralDistribution", {
                    data: encrypt,
                  });
                  window.location.reload(true)

                  // const resultrefralDistribution = await instance.post(
                  //   "/refralDistribution",
                  //   {
                  //     data: encrypt,
                  //   }
                  // );

                  // const resultsrefralDistribution = decryptData(
                  //   resultrefralDistribution.data.data
                  // );
                  // console.log(
                  //   "🚀 ~ resultsrefralDistribution:",
                  //   resultsrefralDistribution
                  // );
                  // toast.success(
                  //   "🚀 ~ submitHandler ~ results:",
                  //   resultsrefralDistribution
                  // );
                  // toast.error(
                  //   "🚀 ~ submitHandler ~ results:",
                  //   resultsrefralDistribution
                  // );
                } else {
                  toast.error(resultsstaking.message);
                  // console.log("resultsstaking.message", resultsstaking.message);
                }
              } else {
                console.log("Something went wrong ⚠");
              }
            })
            .on("error", function (error, receipt) {
              // console.log("🚀 ~ receipt:", receipt);
              toast.error("something went wrong, not confirmed staking");
              // console.log("🚀 ~ error:", error);
            });
        });
    } catch (error) {
      setLoading(false);
      // toast.error("🚀 ~ consttransferFrom_address_to_address= ~ error:", error);
    } finally {
      setLoading(false);
      // window.location.reload(true)
    }
  };

  const walletAd = localStorage.getItem("metamask");
  // console.log("🚀 ~ Staking ~ walletAd:", walletAd);
  const transferToken = async () => {
    if (amount >= 20000) {
      // console.log("address=======>", address);
      if (
        walletAd === undefined ||
        walletAd === "" ||
        walletAd === null
      ) {
        toast.error("Please connect your wallet");
      } else if (!window.ethereum) {
        toast.error("No crypto wallet found");
        window.open("https://metamask.io/");
      } else {
        try {
          amount === "" && toast.error("Enter Amount");
          await window?.ethereum?.enable();
          // const wallet = await window?.ethereum?.enable();
          // localStorage.setItem("metamask1", wallet[0].toString());
          // setwalletAddress(wallet[0]?.toString());
          await transferFrom_address_to_address(amount.toString());
        } catch (err) {
          // toast.error(err.message);
          toast.error("Please try again later");
          // window.open("https://metamask.io/");
        }
      }
    } else {
      alert("Required Minimum Amount 20000");
    }
    // toast.error(
    //   "Due to an urgent contract upgrade, withdrawal has paused. It will resume shortly.Appreciate your patience.",
    //   { duration: 3000 }
    // );
  };
  // const transferWizzTokens = async () => {
  //   try {
  //     const wallet = new ethers.Wallet(wizzPrivateKey, provider);
  //     const tokenAddress = "0x2aD69E58071b95C047bF78dc804DB3662275b64A"; //token address wec.
  //     const tokenContract = new ethers.Contract(tokenAddress, wizzABI, wallet);

  //     const parsedAmount = ethers.utils.parseUnits(
  //       withdrawAmount.toString(),
  //       18
  //     );
  //     console.log("🚀 ~ transferWizzTokens ~ parsedAmount:", parsedAmount);

  //     setLoading2(true);
  //     const tx = await tokenContract.transfer(walletAddress, parsedAmount, {
  //       // gasPrice: 10000000000,
  //       // gasLimit: 8500000,
  //     });
  //     console.log(`Transaction hash: ${tx.hash}`);

  //     const receipt = await tx.wait();
  //     console.log(`Transaction confirmed in block ${receipt.blockNumber}`);

  //     if (receipt.blockNumber) {
  //       toast.success(`Withdrawn of ${withdrawAmount} tokens is Successful`);
  //       window.location.reload(true);
  //     }
  //   } catch (error) {
  //     toast.error("Please try again later");
  //     console.log("error-------------", error);
  //   } finally {
  //     setLoading2(false);
  //     // closeModal();
  //   }
  // };
  // const WithdrawWEC = async (e) => {
  //   // e.preventDefault();

  //   try {
  //     const WithdrawWECData = await instance.post("/withdrawStakingProfit", {
  //       amount: withdrawAmount,
  //       toAddress: walletAd,
  //       role: "PROFIT",
  //     });
  //     const decryptDataData = decryptData(WithdrawWECData.data.data);
  //     if (decryptDataData.status === true) {
  //       transferWizzTokens();
  //       // toast.success(decryptDataData.message);
  //     } else {
  //       toast.error(decryptDataData.message);
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ submitHandler ~ err:", error);
  //     toast.error("Please try again later");
  //   }
  //   // finally {
  //   //   setLoading2(false);
  //   // }
  // };
  // const WithdrawRefaralProfit = async () => {
  //   try {
  //     setLoading2(true);
  //     const WithdrawWECData = await instance.post("/withdrawRefaralProfit", {
  //       amount: withdrawAmount,
  //       toAddress: walletAd,
  //       role: "REFARAL_PROFIT",
  //     });
  //     const decryptDataData = decryptData(WithdrawWECData.data.data);
  //     if (decryptDataData.status === true) {
  //       toast.success(decryptDataData.message) && window.location.reload(true);
  //     } else {
  //       toast.error(decryptDataData.message);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading2(false);
  //   }
  // };
  // const historyData = async () => {
  //   try {
  //     const result = await instance.get(`/stakingDetails/${walletAddress}`);
  //     const results = decryptData(result.data.data);
  //     if (results.status) {
  //       toast.success(results.message);
  //       // setbusdprice(results.data.price);
  //     } else {
  //       toast.error(results.message);
  //     }
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   historyData();
  // }, []);

  //withdraw
  const withdrawWizzTokens = async () => {
    // Check if MetaMask is installed and has a provider

    try {
      const contractaddress = "0x05879bDb48F58594e88dfF248355C1f7F54E5dF0";

      const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
      console.log("🚀 ~ transferWizzTokens ~ walletProvider:", walletProvider);
      const sendContractTx = new ethers.Contract(
        contractaddress,
        withdrawABI,
        walletProvider.getSigner()
      );

      // Check if value is defined and not null
      setLoading1(false);
      if (withdrawAmount) {
        const balanceInEther = ethers.utils.parseUnits(
          withdrawAmount.toString(),
          18
        );
        const gasLimit = 300000;
        const withdrawData = await sendContractTx.withdraw(balanceInEther, {
          gasLimit,
        });
        if (withdrawData) {
          // if (selectedOption === "WEC Rewards") {
          //   WithdrawWEC();
          // } else {
          //   WithdrawRefaralProfit();
          // }
          toast.success("withdraw successfully");
        }
      } else {
        console.log("Value is not defined or null");
      }
    } catch (error) {
      // console.log("🚀 ~ transferWizzTokens ~ error===================:", error);
      toast.error("Please try again later");
    } finally {
      setLoading(false);
      setLoading2(false);
      // closeModal();
    }
  };

  const WithdrawWEC = async () => {
    if (withdrawAmount >= 1000) {
      try {
        setLoading2(true);
        const WithdrawWECData = await instance.post("/withdrawStakingProfit", {
          amount: withdrawAmount,
          toAddress: walletAd,
          role: "PROFIT",
        });

        const decryptDataData = decryptData(WithdrawWECData?.data?.data);
        console.log("🚀 ~ WithdrawWEC ~ decryptDataData:", decryptDataData);
        if (decryptDataData.status === true) {
          // withdrawWizzTokens();
          toast.success(decryptDataData.message) && window.location.reload(true);
          // toast.success(decryptDataData.message)
          setLoading2(false);
        } else {
          setLoading2(false);
          toast.error(decryptDataData.message);
        }
      } catch (error) {
        console.log("🚀 ~ WithdrawWEC ~ error:", error)
        toast.error("Try agin later");
      }
    } else {
      toast.error("The amount must be greater than or equal to 1000");
    }
    // finally {
    //   setLoading2(false);
    // }
  };
  const WithdrawRefaralProfit = async () => {
    if (withdrawAmount >= 1000) {
      try {
        setLoading2(true);
        const WithdrawWECData = await instance.post("/withdrawRefaralProfit", {
          amount: withdrawAmount,
          toAddress: walletAd,
          role: "REFARAL_PROFIT",
        });

        const decryptDataData = decryptData(WithdrawWECData?.data?.data);
        console.log(
          "🚀 ~ WithdrawRefaralProfit ~ decryptDataData:",
          decryptDataData
        );
        if (decryptDataData.status === true) {
          // withdrawWizzTokens();
          toast.success(decryptDataData.message) && window.location.reload(true);
          // toast.success(decryptDataData.message)
          setLoading2(false);
        } else {
          setLoading2(false);
          toast.error(decryptDataData.message);
        }
      } catch (error) {
        console.log("🚀 ~ WithdrawRefaralProfit ~ error:", error)
        toast.error("Try agin later");
      }
      //  finally {
      //   setLoading2(false);
      // }
    } else {
      toast.error("The amount must be greater than 1000");
    }
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // if (selectedValue === "WEC Rewards") {
    //   WithdrawWEC();
    // } else if (selectedValue === "WEC Earnings") {
    //   WithdrawRefaralProfit();
    // }
  };

  const withdrawUnstakedAmount = async () => {
    const withdrawUnstakedAmountData = await instance.post("/withdrawUnstakedAmount", {
      amount: 1,
      toAddress: localStorage.getItem("metamask"),
    });
    const decryptDataData = decryptData(withdrawUnstakedAmountData?.data?.data);
    console.log("🚀 ~ withdrawUnstakedAmount ~ decryptDataData:", decryptDataData)
  }

  // const withdrawUnstakedAmount1 = async () => {
  //   const withdrawUnstakedAmountData = await instance.post("/admin/addFields");
  //   // const decryptDataData = decryptData(withdrawUnstakedAmountData?.data?.data);
  //   // console.log("🚀 ~ withdrawUnstakedAmount ~ decryptDataData:", decryptDataData)

  //   console.log("🚀 ~ withdrawUnstakedAmount ~ withdrawUnstakedAmountData:", withdrawUnstakedAmountData)
  // }
  // const historyDatas = async () => {
  //   const walletAddress = localStorage.getItem("metamask");
  //   const userId = localStorage.getItem("userId");
  //   try {
  //     const result = await instance.get(
  //       `/stakingDetails?walletAddress=${walletAddress}&userId=${userId}`
  //     );

  //     const results = decryptData(result.data.data);
  //     console.log("🚀 ~ historyData decryptData ~ results decryptData:--->>>", results)
  //     const unstakedBalance = results?.data?.unstakableWecBalance
  //     console.log("🚀 ~ historyData ~ unstakedBalance:", unstakedBalance)
  //     setUnstakeBalance(unstakedBalance)
  //     dispatch(fetchData(results?.data));
  //     if (results?.status) {
  //       toast.success(results?.message);
  //       // setbusdprice(results.data.price);
  //     } else {
  //       toast.error(results?.message);
  //     }
  //   } catch (err) {
  //     // console.log("🚀 ~ historyData ~ err:", err);
  //   }
  // };
  // useEffect(() => {
  //   historyDatas();
  // }, [walletAd])

  const ABTHistoryData = useSelector((state) => state?.user?.historyData?.ABTStakingData)
  console.log("🚀 ~ ABTHistoryData:", ABTHistoryData)


  return (
    <>
      {/* {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                <div className="flex items-center justify-end p-6  rounded-b">
                  <button
                    className="text-white background-transparent font-bold uppercase text-2xl   outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    X
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <img src="/launch.gif" alt="description" className="h-96" />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null} */}

      {loading2 && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <div className="flex justify-center items-center flex-col">

                <p className="text-center text-xl">
                  Please wait...Your transaction is being processed...
                </p>
                <p className="text-center mt-5">
                  <Circles
                    height="50"
                    width="50"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading3 && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <p className="text-center">
                If you have staked for 1st time, Please wait for 24 hours to
                withdraw.
              </p>
            </div>
          </div>
        </div>
      )}
      {withdrawPopup && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          {metaLoader ? (
            <div className="text-white text-4xl">Loading...</div>
          ) : (
            <div className="bg-[#030239] p-10 rounded-3xl grid place-content-center">
              <div className="mb-5">
                {/* <p className="text-gray-300 my-5 w-[40%] text-center">There is a 5% withdrawal fees will be deduct and rest will be in your wallet. </p> */}
                <label className="text-white text-lg">Please Select</label>
                <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                  <select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    className="w-full bg-transparent p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg"
                  >
                    <option value="">Select an option</option>
                    <option value="WEC Rewards">WEC Rewards</option>
                    <option value="WEC Earnings">WEC Earnings</option>
                  </select>
                </div>
              </div>
              <div className="bot-left1 rounded-3xl flex items-center text-[#A9A9A9] mt-1">
                <input
                  type="number"
                  value={withdrawAmount}
                  placeholder="Enter Amount"
                  min="1000"
                  className="py-8 px-5 md:px-10 nodetype-bg rounded-3xl  text-white "
                  onChange={(event) => {
                    const amount = event.target.value;
                    if (selectedOption != "") {
                      if (selectedOption === "WEC Rewards") {
                        if (amount >= historyData?.profit) {
                          toast.error("not enough rewards");
                        } else {
                          setWithdrawAmount(amount);
                        }
                      } else if (selectedOption === "WEC Earnings") {
                        if (amount >= historyData?.refaralProfit) {
                          toast.error("not enough earnings");
                        } else {
                          setWithdrawAmount(amount);
                        }
                      }
                    } else {
                      toast.error("Please select option");
                    }
                  }}
                />
              </div>
              {withdrawAmount < 1000 && <p className="text-red-500 text-sm w-52 mt-1 mx-2">The amount must be greater than or equal to 1000</p>}

              <div className="md:flex justify-center gap-10">
                <div
                  className="w-[50%] my-5 mx-auto"
                  // onClick={() => {
                  //   WithdrawWEC();
                  //   setWithdrawPopup(false);
                  // }}
                  // onClick={
                  //   () => {
                  //     transferWizzTokens();
                  //     setLoading1(true);
                  //   }
                  onClick={() => {
                    if (withdrawAmount >= 1000) {
                      // if (withdrawAmount) {
                      if (selectedOption === "WEC Rewards") {
                        WithdrawWEC();
                      } else if (selectedOption === "WEC Earnings") {
                        WithdrawRefaralProfit();
                      }

                      setWithdrawPopup(false);
                    }
                    else {
                      toast.error("Please enter more than 1000");
                    }
                  }
                  }
                // }
                >
                  <Button btn={"Send"} />
                </div>
                <div
                  className="w-[50%] my-5 mx-auto"
                  onClick={() => {
                    setWithdrawPopup(false);
                  }}
                >
                  <Button btn={"Cancel"} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {loading && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <p>
                Your transaction is under process. This will take upto 15
                seconds. Please wait...
              </p>
            </div>
          </div>
        </div>
      )}
      {(!loading || !loading2) && (
        <>
          {/* <div onClick={() => withdrawUnstakedAmount()} className="border p-2 cursor-pointer w-fit text-white mx-10 my-2">withdrawUnstakedAmount</div> */}
          <MainTitle title={"WEC STAKING"} />
          <div className="container mx-auto text-center mt-3 text-white">
            <h2 className="font-bold">
              INTRODUCING OUR CUTTING-EDGE CRYPTOCURRENCY STAKING PLATFORM;
            </h2>
            <p className="text-justify max-w-xs lg:max-w-3xl container mx-auto my-5">
              A revolutionary ecosystem empowering users to grow their WEC
              effortlessly. Through staking, users can securely lock their WEC
              and earn daily rewards. Our platform is a one-stop solution for
              individuals seeking to maximize their earnings and engage with the
              dynamic world of cryptocurrencies.
            </p>
            <h2 className="font-bold">
              JOIN US TODAY AND UNLOCK THE TRUE POTENTIAL OF YOUR WEC.
            </h2>
          </div>
          <div className="text-white">
            <div className=" mt-10 lg:grid grid-cols-2 justify-items-stretch">
              {data?.map((item) => (
                <div
                  key={item.id}
                  className={`lg:w-[60%]  md:w-[70%] md:mx-auto mx-5 lg:mx-5 grid grid-cols-2 place-items-center place-content-center gap-6 p-[1px] bot-left1 rounded-lg ${item?.grids}`}
                >
                  <div className="p-4">
                    <p className="text-sm mb-1 text-white/60">Locking period</p>
                    <p className="font-medium text-lg">{item.time}</p>
                  </div>
                  <div className="p-4">
                    <p className="text-sm mb-1 text-white/60">Multiplier</p>
                    <p className="font-medium text-lg">{item.stakeRewards}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="lg:grid grid-cols-2 justify-items-stretch ">
            <div className="lg:w-[60%] md:w-[70%] md:mx-auto mx-5 lg:mx-5 grid justify-self-end">
              <div className="nodetype-bg  rounded-2xl items-stretch md:p-16 p-10 flex flex-col gap-5 justify-center mt-10">
                <div className="text-white">
                  <div className="flex justify-between">
                    <label className="text-white text-lg">Amount</label>
                    <div className="lg:mt-2 text-sm text-gray-400">
                      Minimum 20000
                    </div>
                  </div>
                  <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                    <input
                      className={`w-full bg-transparent  p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg`}
                      type="text"
                      name="amount"
                      placeholder="Enter Amount Minimum 500"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  {amount < 20000 && (
                    <p className="text-red-500">required minimum 20000</p>
                  )}
                </div>
                <div>
                  <label className="text-white text-lg">
                    Duration (Months)
                  </label>
                  <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                    <select
                      onChange={(e) => setDuration(e.target.value)}
                      className="w-full bg-transparent  p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg"
                    >
                      <option value="6">6</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>
                {/* <div>
                <label className="text-white text-lg">ReferedBy</label>
                <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                  <input
                    className={`w-full bg-transparent  p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg `}
                    type="text"
                    name="refered by"
                    placeholder="Enter Refered By"
                    value={referedBy}
                    onChange={(e) => setReferedBy(e.target.value)}
                  />
                </div>
              </div> */}
                {/* <div>
                  <label className="text-white text-lg">Wallet Address</label>
                  <div className="bot-left1 rounded-md flex items-center text-[#A9A9A9] mt-1">
                    <input
                      className={`w-full bg-transparent  p-2 outline-none focus:shadow-outline text-[#A9A9A9] text-lg `}
                      type="text"
                      name="wallet address"
                      placeholder="Enter Wallet Address"
                      value={walletAddress}
                      onChange={(e) => setwalletAddress(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="flex justify-center gap-4 my-4">
                  {/* <div className="text-center">
                  <button onClick={openmetamask}>
                    <Button
                      btn={`${
                        address === ""
                          ? "Connect Wallet"
                          : address?.slice(0, 3) + "...." + address?.slice(-3)
                      }`}
                    />
                  </button>
                </div> */}
                  {/* {address ? ( */}
                  <div className="flex justify-center">
                    <div
                      onClick={() => {
                        transferToken();
                        // setLoading2(true);
                        // toast.error("Staking is closed")
                        // transferFrom_address_to_address(amount.toString())
                      }}
                    >
                      <Button btn={"Stake WEC"} />
                    </div>
                  </div>
                  {/* ) : null} */}
                </div>
              </div>
            </div>
            <div className="lg:w-[60%] md:w-[70%] md:mx-auto mx-5 lg:mx-5 grid justify-self-start ">
              <div className="md:mt-10 mt-4 text-white">
                <div>
                  <div className="nodetype-bg rounded-lg bg-black/10 w-full  md:py-10 py-10 px-5">
                    <div className="p-4">
                      <div className="flex justify-center text-center border-b-2 border-[#393d60] mb-4">
                        <span className="md:text-xl mb-3">WEC Staking Data</span>
                      </div>
                      <div className="flex gap-5 justify-between">
                        <div>
                          <div className="flex gap-2 items-center border-b-2 border-b-gray-700">
                            <p>Wallet Referred :-</p>
                            <div className="flex gap-2 items-center my-2 ">
                              <p className="mt-1">{`${historyData >= 0 ||
                                historyData === undefined ||
                                historyData === null ||
                                historyData.referedWallets === undefined
                                ? "(0)"
                                : historyData.referedWallets
                                }`}</p>
                              <img
                                src={weclogo}
                                alt="weclogo"
                                className=""
                              />
                            </div>
                            {/* <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`(${
                              historyData?.staking >= 0 ||
                              historyData?.staking === undefined ||
                              historyData?.staking === null
                                ? "(0)"
                                : historyData?.staking.length
                            })`}</p>
                            <img
                              src={weclogo}
                              alt="weclogo"
                              className=""
                            />
                          </div> */}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (ABTHistoryData?.ABTStaking?.length <= 0) {
                              toast.error("Please Stake ABT to Withdraw a WEC")
                            } else {

                              if (
                                localStorage.getItem("blockWithdraw") ===
                                "Unblocked" ||
                                localStorage.getItem("blockWithdraw") === null ||
                                localStorage.getItem("blockWithdraw") ===
                                undefined
                              ) {
                                if (
                                  localStorage.getItem("metamask") === "" ||
                                  localStorage.getItem("metamask") ===
                                  undefined ||
                                  localStorage.getItem("metamask") === null
                                ) {
                                  toast.error("Please connect wallet");
                                } else if (historyData?.profit > 0) {
                                  setWithdrawPopup(true);
                                } else {
                                  setLoading3(true);
                                  setTimeout(() => {
                                    setLoading3(false);
                                  }, 3000);
                                }
                              } else {
                                toast.error("contact admin to restore account");
                              }
                              // // WithdrawWEC();
                              // toast.error(
                              //   "Due to an urgent contract upgrade, withdrawal has paused. It will resume shortly.Appreciate your patience..",
                              //   { duration: 3000 }
                              // );
                            }
                          }}
                        >
                          <Button btn={"Withdraw"} />
                        </div>
                      </div>
                      <div className="mt-12 grid md:grid-cols-2 gap-8">
                        <div>
                          <p>Staked WEC</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`${StakedWEC ? StakedWEC : "(0)"
                              }`}</p>
                            <img
                              src={weclogo}
                              alt="weclogo"
                              className=""
                            />
                          </div>
                        </div>

                        <div>
                          <p>WEC Rewards</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`${historyData >= 0 ||
                              historyData === undefined ||
                              historyData === null ||
                              historyData?.profit === undefined
                              ? "(0)"
                              : historyData?.profit?.toFixed(2)
                              }`}</p>
                            <img
                              src={weclogo}
                              alt="weclogo"
                              className=""
                            />
                          </div>
                        </div>
                        <div>
                          <p>WEC Earnings</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`${historyData >= 0 ||
                              historyData === undefined ||
                              historyData === null ||
                              historyData?.refaralProfit === undefined
                              ? "(0)"
                              : historyData?.refaralProfit?.toFixed(2)
                              }`}</p>
                            <img
                              src={weclogo}
                              alt="weclogo"
                              className=""
                            />
                          </div>
                        </div>
                        <div>
                          <p>Available Unstake Balance</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            {historyData?.unstakableWecBalance ? historyData?.unstakableWecBalance : "(0)"}
                            <img
                              src={weclogo}
                              alt="weclogo"
                              className=""
                            />
                          </div>
                        </div>
                        {/* <div>
                          <p>Your Rewerds</p>
                          <div className="flex gap-2 items-center my-2 border-t-2 border-t-gray-700">
                            <p className="mt-1">{`(${
                              historyData >= 0 ||
                              historyData === undefined ||
                              historyData === null
                                ? "(0)"
                                : historyData?.profit
                            })`}</p>
                            <img
                              src={weclogo}
                              alt="weclogo"
                              className=""
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="nodetype-bg rounded-lg w-full mt-4">
                    <div className="grid grid-cols-4 bg-black/10 border-b border-b-black p-4 rounded-t-md place-items-center min-h-[60px]">
                      <div className="col-span-2">
                        <p className="md:text-xl">Earning overview</p>
                        <p className="text-sm">My assets</p>
                      </div>
                      <div>
                        <p>1 day(s)</p>
                      </div>
                      <div>
                        <p>30 day(s)</p>
                      </div>
                    </div>

                    <div className="grid grid-cols-4 bg-[#030239] py-2 place-items-center">
                      <div className="col-span-2 flex items-center gap-2">
                        <div className="bg-black p-2 rounded-full">
                          <img
                            src={weclogo}
                            alt="weclogo"
                            className="w-4 h-4"
                          />
                        </div>
                        <p className="text-left">WIZZ</p>
                      </div>
                      <div>0.00</div>
                      <div>0.00</div>
                    </div>

                    <div className="grid grid-cols-4 bg-black/10 py-2 rounded-b-md place-items-center min-h-[60px]">
                      <div className="col-span-2">
                        <p className="text-sm text-left">Total</p>
                      </div>
                      <div>
                        <div className="bg-black p-2 rounded-full">
                          <img
                            src={weclogo}
                            alt="weclogo"
                            className="w-4 h-4"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="bg-black p-2 rounded-full">
                          <img
                            src={weclogo}
                            alt="weclogo"
                            className="w-4 h-4"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Staking;